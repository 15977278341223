import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendObservable, BackendService } from './backend.service';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { SiteModel } from '../models/site.model';
import { EcoMateConsumerData, VesselData } from '../views/visits-reports/emission-report/overall-data.interface';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BackendService {
  activated: boolean;

  getEcomateConsumerData(vesselId: string, from: string, to: string): Observable<EcoMateConsumerData[]> {
    return this.post<EcoMateConsumerData[]>(`ecomate/consumerData`, { vesselId, from, to });
  }

  getEcomateTimeseriesData(vesselId: string, from: string, to: string, interval: string): Observable<VesselData> {
    return this.post<VesselData>(`ecomate/timeseriesData`, { vesselId, from, to, interval });
  }

  /**
   * gets historical track from marinetraffic, currently only 60 days are supported, fromdate and todate are throwing errors
   *
   * @param vesselId
   * @param from
   * @param to
   * @returns
   */
  getHistoricalTrack(vesselId: string, from: string, to: string): Observable<any> {
    // const encodedFrom = encodeURIComponent(from);
    // const encodedTo = encodeURIComponent(to);
    const apiUrl = `https://services.marinetraffic.com/api/exportvesseltrack/7d0433ad3517fc601950a75c93edfed2f21dbad4?v=3&msgtype=simple&protocol=jsono&days=60&period=hourly&shipid=${vesselId}`;

    // const apiUrl = `https://services.marinetraffic.com/api/exportvesseltrack/7d0433ad3517fc601950a75c93edfed2f21dbad4?v=3&msgtype=simple&protocol=jsono&fromdate=${encodedFrom}&todate=${encodedTo}&shipid=${vesselId}`;
    return this.http.get(apiUrl);
  }

  getSite(siteId: number): Observable<SiteModel> {
    return this.get<SiteModel>(`${siteId}/get`);
  }

  editSite(siteId: number, mainAssetId?: number, editingOptions?: ISiteEditingOptions): Observable<SiteModel> {
    return this.post<SiteModel>(`${siteId}/edit`, { data: { mainAssetId }, editingOptions });
  }

  createSite(name: string, mainAssetId: number): Observable<SiteModel> {
    return this.post<SiteModel>(`create`, { data: { mainAssetId, name } });
  }

  getAllSites(
    criteria?: any,
    limit?: number,
    offset?: number,
    options?: IGetAllSitesOptions
  ): Observable<ISequelizeCount<SiteModel>> {
    if (!criteria && !limit && !offset && !options) {
      return this.cachingService.createCachingSubscription(
        'getAllSites',
        this.post<ISequelizeCount<SiteModel>>(`all/get`, {})
      );
    }
    return this.post<ISequelizeCount<SiteModel>>(`all/get`, { criteria, limit, offset, options });
  }

  editSiteUser(siteId: number, userId: number, presentFrom: Date, presentTo: Date): Observable<any> {
    return this.post<any>(`${siteId}/editSiteUser`, {
      userId,
      presentFrom,
      presentTo
    });
  }

  generateTvOneTimeCode(): Observable<any> {
    return this.post<any>('generate-code');
  }

  validateTvOneTimeCode(code: string): Observable<any> {
    return this.post<any>('validate-code', { code });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`site/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`site/${url}`, options);
  }
}

export interface ISiteEditingOptions {
  retainCertificates: boolean;
}

export interface IGetAllSitesOptions {
  includeMainAsset?: boolean;
  includeWarehouses?: boolean;
  includeMainAssetAndCertificates?: boolean;
}
