import { Component, computed, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JustToDoComponent } from '../../dialogs/justto-do/justto-do.component';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { MobileViewService } from 'src/app/services/mobile-view.service';

import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { JwtService } from 'src/app/services/jwt.service';
import { DecodedJwt } from 'src/app/services/token.interface';
import { CapabilityCheckPipe } from 'src/app/pipes/check-capability.pipe';
import { Capability } from 'src/app/interfaces/user/capability.interface';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatBadgeModule,
    CapabilityCheckPipe
  ]
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() inspectorTasks: number;
  userLogin: DecodedJwt;
  mobile: boolean;
  version = localStorage.getItem('app_version');
  capabilities = this.jwtService.userData?.role.capabilities;
  Capability = Capability;

  constructor(
    private readonly jwtService: JwtService,
    private readonly mobileService: MobileViewService,
    private readonly dialog: MatDialog,
    private readonly processHandlerService: ProcessHandlerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inspectorTasks) {
      this.inspectorTasks = this.inspectorTasks;
    }
  }

  ngOnInit(): void {
    this.mobile = this.mobileService.detectMobileDevice();
    this.userLogin = this.jwtService.userData
    this.jwtService.$loginChange.subscribe((loggedIn) => {
      this.userLogin = loggedIn? this.jwtService.userData : null;
    });
  }

  onToDoClicked() {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    this.dialog.open(JustToDoComponent, { ...dimensions });
  }

  onLogoutClicked(): void {
    this.jwtService.token = null;
  }

  get roleId() {
    return this.userLogin?.role.id;
  }
}
