<ng-container *transloco="let t">
  <mat-menu #appMenu="matMenu" xPosition="before">
    @if (mobile || userLogin?.roleId === 13) {
      <p class="space-sm-left space-sm-right menu-item flex align-center">
        <img src="/assets/logo/logo_default_small.png" height="20px" /> {{ version }}
      </p>
      <hr />
    }
    @if (![2, 5, 12, 13].includes(userLogin?.roleId)) {
      <button mat-menu-item routerLink="/user/me">
        <mat-icon class="material-symbols-outlined">account_box</mat-icon> {{ t('components.useravatar.profile') }}
      </button>
    }
    @if (userLogin?.roleId === 6) {
      <button mat-menu-item routerLink="/inspector/tasks">
        <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">task</mat-icon>
        {{ t('components.useravatar.tasks') }}
      </button>
    }
    @if (userLogin?.roleId === 10) {
      <button mat-menu-item routerLink="/equipment/user">
        <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">task</mat-icon>
        {{ t('components.useravatar.tasks') }}
      </button>
    }
    @if ([1, 8].includes(userLogin?.roleId)) {
      <button mat-menu-item (click)="onToDoClicked()">
        <mat-icon>task</mat-icon>
        {{ t('components.useravatar.todo') }}
      </button>
    }
    @if ([1, 8].includes(userLogin?.roleId)) {
      <button mat-menu-item routerLink="/user/settings">
        <mat-icon>settings</mat-icon> {{ t('components.useravatar.settings') }}
      </button>
    }
    <button mat-menu-item (click)="onLogoutClicked()"><mat-icon>exit_to_app</mat-icon> Logout</button>
  </mat-menu>
  @if (userLogin) {
    <button mat-button [matMenuTriggerFor]="appMenu">
      <span class="hidden-xs"> {{ t('components.useravatar.greeting') }}, {{ userLogin.user.forename }} </span>
      <mat-icon class="visible-xs">account_box</mat-icon>
      <mat-icon [matBadge]="inspectorTasks" matBadgePosition="below after" matBadgeColor="accent">more_vert</mat-icon>
    </button>
  }
</ng-container>
