export const environment = {
  production: false,
  //   apiUrl: 'http://localhost:3002/',
  //   wsApiUrl: 'localhost:3002',
  apiUrl: 'https://stagingapi.gefo.app/',
  wsApiUrl: 'stagingapi.gefo.app',
  frontentUrl: 'https://staging.gefo.app/',
  languages: { de: 'Deutsch', en: 'English', nl: 'Nederlands', pl: 'Polský', cs: 'Česky' },
  environment: 'staging-gefo',
  developerEmails: ['info@complink.app', 'hans.voelzer@com-and.de', 'hans.voelzer@outlook.de'],
  googleMapAPI: 'AIzaSyAZyZbCwEXXKrmDeejMwpMt4VvHmP-BMDk',
  appTitle: 'GEFO.APP',
  appVersion: require('../../package.json').version + '-stg'
};
