import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MemoryService } from './services/memory.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private memory: MemoryService,
    private router: Router,
    private routeService: RouteService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.memory.isLoggedIn()) {
      const userRole = this.memory.getRole();
      // if a route is not activated for a specific role, fall back
      if (route.data.role && !route.data.role.includes(userRole)) {
        const loginData = this.memory.getLoginData();
        if (loginData.role?.entryUri) {
          this.router.navigate([loginData.role.entryUri]);
          return false;
        }

        // prevent looping through user/dashboard
        if (url !== '/user/dashboard') this.router.navigate(['/user/dashboard']);
        return false;
      }
      return true;
    }
    // Store the attempted route
    const decodedUrl = decodeURIComponent(url);
    this.routeService.setAttemptedRoute(decodedUrl);
    this.router.navigate(['/auth/login']);
    return false;
  }
}
