import { Pipe, PipeTransform } from '@angular/core';
import { Capability } from 'src/app/interfaces/user/capability.interface';

@Pipe({
  name: 'capabilityCheck',
  standalone: true
})
export class CapabilityCheckPipe implements PipeTransform {
  transform(capabilities: Capability[], requiredCapability: string, strict: boolean = false): boolean {
    if (typeof capabilities === 'string') capabilities = JSON.parse(capabilities);

    if (strict) {
      return capabilities.includes(requiredCapability);
    }
    return capabilities.includes(requiredCapability) || capabilities.includes(Capability.All);
  }
}
