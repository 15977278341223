import { Injectable } from '@angular/core';
import { SiteModel } from 'src/app/models/site.model';
import { Address, GoogleSiteModel } from '../interfaces/maps/google-site-model.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { CachingService } from './caching.service';

@Injectable({ providedIn: 'root' })
export class GoogleMapService {
  public colors: { SUCCESS: string; WARN: string; ERROR: string; DEFAULT: string; NEUTRAL: string; };

  constructor(
    private http: HttpClient,
    private cachingService: CachingService
  ) {
    this.colors = {
      SUCCESS: getComputedStyle(document.documentElement).getPropertyValue('--notify-success'),
      WARN: getComputedStyle(document.documentElement).getPropertyValue('--notify-warn'),
      ERROR: getComputedStyle(document.documentElement).getPropertyValue('--notify-error'),
      DEFAULT: getComputedStyle(document.documentElement).getPropertyValue('--notify-no-status'),
      NEUTRAL: getComputedStyle(document.documentElement).getPropertyValue('--table-info')
    }
  }

  /**
   * Sets the marker label if a specific threshold is exceeded.
   *
   * @param allSites
   * @param currentZoom
   * @param threshold
   * @returns
   */
  zoomChanged(allSites: GoogleSiteModel[], currentZoom: number, threshold = 12): GoogleSiteModel[] {
    if (currentZoom >= threshold) {
      allSites.forEach((element) => {
        element.symbol = this.getSymbol(element, true);
      });
    } else {
      allSites.forEach((element) => {
        element.symbol = this.getSymbol(element, false);
      });
    }
    return allSites;
  }

  checkIfLocationIsOutdated(positionReceivedAt: Date) {
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - new Date(positionReceivedAt).getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    // Check if the time difference is greater than 2 hours
    if (hoursDifference > 2 && hoursDifference <= 11) {
      return 1;
    }
    if (hoursDifference > 12) {
      return 2;
    }
    return 0;
  }

  getStyles(): any[] {
    return [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }]
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#8AB4F8' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8AB4F8' }]
      }
    ];
  }

  getNearbySites(sites: SiteModel[], location: { lat: number; lng: number }): SiteModel[] {
    const radius = 15; // km
    const nearIds = sites
      .map((e) => {
        return {
          siteId: e.siteId,
          distance: this.getDistanceFromLatLngInKm(location.lat, location.lng, e.latitude, e.longitude)
        };
      })
      .filter((e) => e.distance < radius)
      .map((e) => e.siteId);

    return sites.filter((e) => nearIds.includes(e.siteId));
  }

  /**
   * Gets symbol as svg
   *
   * @param site
   * @returns google.maps.Symbol
   */
  getSymbol(site: SiteModel, withLabel?: boolean, tv?: boolean): any {
    const heading = site.locationMeta.find((e) => e.key === 'heading')?.value;
    const speed = site.locationMeta.find((e) => e.key === 'position.speed')?.value;

    if (!site.color && site.mainAsset?.condition) {
      switch (site.mainAsset.condition) {
        case 1:
          site.color = this.colors.SUCCESS;
          break;
        case 3:
          site.color = this.colors.WARN;
          break;
        case 5:
          site.color = this.colors.ERROR;
          break;
        default:
          site.color = this.colors.DEFAULT;
      }
    }

    if (heading && Number(speed) > 0.1) {
      const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
        <g>
        <path d="M9.5 42 8 40.5 24 4l16 36.5-1.5 1.5L24 35.4Z" fill="${site.color}" stroke="#000000" stroke-width="2" />
        </g>
        </svg>`;

      const markerDiv = document.createElement('div');
      const paragraphLabel = document.createElement('p');
      paragraphLabel.className = `${tv ? 'font-semibold text-xxxl' : 'font-medium text-md'}`;
      paragraphLabel.style.position = 'absolute';
      paragraphLabel.style.width = `${tv ? '320px' : '160px'}`;
      paragraphLabel.style.left = `${tv ? '24px' : '12px'}`;
      if (withLabel) paragraphLabel.innerText = site.name;

      const innerDiv = document.createElement('div');
      innerDiv.className = 'site-inner-div';
      innerDiv.innerHTML = svgString;
      innerDiv.style.transform = ` translate(-50%, 24px) rotate(${heading}deg) scale(${tv ? 1.2 : 0.5})`;
      markerDiv.appendChild(innerDiv);
      markerDiv.appendChild(paragraphLabel);

      return markerDiv;
    } else {
      const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
                            <g>
                                <path d="M24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Z"
                                        fill="${site.color}" stroke="#000000" stroke-width="4"/>
                            </g>
                        </svg>`;

      const markerDiv = document.createElement('div');
      const paragraphLabel = document.createElement('p');
      paragraphLabel.className = `${tv ? 'font-semibold text-xxxl' : 'font-medium text-md'}`;
      paragraphLabel.style.position = 'absolute';
      paragraphLabel.style.width = `${tv ? '320px' : '160px'}`;
      paragraphLabel.style.left = `${tv ? '24px' : '12px'}`;
      if (withLabel) paragraphLabel.innerText = site.name;

      const innerDiv = document.createElement('div');
      innerDiv.className = 'site-inner-div';
      innerDiv.innerHTML = svgString;
      innerDiv.style.transform = ` translate(-50%, 24px) scale(${tv ? 0.5 : 0.25})`;
      markerDiv.appendChild(innerDiv);
      markerDiv.appendChild(paragraphLabel);

      return markerDiv;
    }
  }

  getBoundsZoomLevel(latNorth, lngEast, latSouth, lngWest, mapContainerHeight, mapContainerWidth) {
    const GLOBE_WIDTH = 256; // a constant in Google's map projection
    const ZOOM_MAX = 21; // also define min zoom, for choosing one asset only
    const latFraction = (this.latRad(latNorth) - this.latRad(latSouth)) / Math.PI;
    const lngDiff = lngEast - lngWest;
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    const latZoom = this.calcZoom(mapContainerHeight, GLOBE_WIDTH, latFraction);
    const lngZoom = this.calcZoom(mapContainerWidth, GLOBE_WIDTH, lngFraction);
    return Math.min(Math.min(latZoom, lngZoom), ZOOM_MAX) - 0.3;
  }

  /**
   * Validate address with google validation api. Use a cached subscription,  we do not want to make the same request twice.
   *
   * Use invalidateAddressCache to delete the cache subscription.
   *
   * @param cacheName
   * @param address
   * @returns
   */
  validateAddress(cacheName: string, address: Address): Observable<any> {
    const body = {
      address: {
        regionCode: address.country, // Assuming country code is in the address object
        locality: address.city,
        addressLines: [`${address.streetNr}, ${address.postal}`] // Combine street and number
      }
    };
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${environment.googleMapAPI}`;
    return this.cachingService.createCachingSubscription(cacheName, this.http.post<any>(url, body));
  }

  invalidateAddressCache(cacheName: string) {
    this.cachingService.invalidateCache(cacheName);
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371000; // Radius of the Earth in meters
    const toRadians = (degrees: number) => degrees * (Math.PI / 180);

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  }

  calculateTotalDistance(gpsDataPoints: { lat: number; lng: number }[]): number {
    let totalDistance = 0;

    for (let i = 0; i < gpsDataPoints.length - 1; i++) {
      const { lat: lat1, lng: lon1 } = gpsDataPoints[i];
      const { lat: lat2, lng: lon2 } = gpsDataPoints[i + 1];
      totalDistance += this.calculateDistance(lat1, lon1, lat2, lon2);
    }

    return totalDistance;
  }

  private latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  private calcZoom(mapPx, worldPx, fraction) {
    return Math.log(mapPx / worldPx / fraction) / Math.LN2;
  }

  /**
   * See Haversine formula for reference
   *
   * @param lat1
   * @param lng1
   * @param lat2
   * @param lng2
   * @returns
   */
  private getDistanceFromLatLngInKm(lat1, lng1, lat2, lng2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  private deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  async geocode(address: any): Promise<any> {
    return firstValueFrom(
      this.http.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleMapAPI}&address=${address}`
      )
    );
  }

  async reverseGeocode(lat: number, lng: number): Promise<string> {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ location: { lat, lng } });
    return response.results[0].plus_code.compound_code.replace(/^\S+\s/, '');
  }
}
