import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private attemptedRoute: string | null = null;

  setAttemptedRoute(route: string): void {
    this.attemptedRoute = route;
  }

  getAttemptedRoute(): string | null {
    return this.attemptedRoute ? decodeURIComponent(this.attemptedRoute) : null;
  }

  clearAttemptedRoute(): void {
    this.attemptedRoute = null;
  }
}
