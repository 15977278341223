<ng-container>
  <p>
    <small class="text-muted">{{ dataField?.name }}</small>
  </p>
  <ng-container *ngIf="!editing">
    <ng-container *ngIf="!isUsingTemplate">
      <div class="flex space-between">
        <div>
          @if (dataField.type === inputTypes.DATE) {
            @if (dataField.name.includes('Received')) {
              <span [class]="calcOutdated(dataField.value)">{{ dataField.value | date: 'short' }} {{ aisInfo }}</span>
            } @else {
              {{ dataField.value | date: 'short' }}
            }
          }
          @else if (dataField.type === inputTypes.YES_NO) {
            <p>{{ dataField.value === '1' ? 'Ja' : 'Nein' }}</p>
          }
          @else if (dataField.type === inputTypes.LINK) {
            <p>
              <a href="{{ dataField.value }}">{{ dataField.value }}</a>
            </p>
          }
          @else if (dataField.type === inputTypes.ARRAY_STRING) {
            <p [innerHTML]="value | nl2br"></p>
          }
          @else if (dataField.type === inputTypes.FILE) {
            104
            <mat-action-list *ngFor="let file of dataField.value; let index = index">
              <ng-container *ngIf="file.mimetype === 'application/pdf'; else noPdf">
                <div class="flex column">
                  {{ file.originalname }}
                  <ngx-extended-pdf-viewer
                    [src]="getPicturePath(dataField.process_id, file)"
                    [textLayer]="true"
                    style="width: 100%; max-height: 250px"
                    [height]="'250px'"
                  ></ngx-extended-pdf-viewer>
                </div>
              </ng-container>
              <ng-template #noPdf>
                <div class="flex column" (click)="downloadFile(file.url)">
                  {{ file.originalname }}
                  <img [src]="getPicturePath(dataField.process_id, file)" />
                </div>
              </ng-template>
            </mat-action-list>
          }
          @else {
            <ng-container *ngIf="isEmail">
              <a href="mailto:{{ value }}">{{ value }}</a>
            </ng-container>
            <ng-container *ngIf="isPhone">
              <a href="tel:{{ value }}">{{ value }}</a>
            </ng-container>
            <ng-container *ngIf="!isPhone && !isEmail">
              <p [innerHTML]="value | nl2br"></p>
            </ng-container>
          }
          <div *ngIf="dataField.changed_by_user_id">Zuletzt bearbeitet von</div>
          <div *ngIf="dataField.dataFieldModifyLogs?.length"></div>
          <div *ngIf="dataField.DataFieldDefinition?.user_editable">
            <a (click)="onEditClicked()"><i class="fa fa-fw fa-edit"></i> Bearbeiten</a>
          </div>
        </div>
        <span *ngIf="!noDate">
          <small>({{ dataField.updatedAt | date }})</small>
        </span>
      </div>
    </ng-container>
  </ng-container>
  <!--<ng-container *ngIf="editing">
  <div class="input-group input-group-sm">
    <input id="value" type="text" class="form-control" [(ngModel)]="editedValue" />
    <div class="input-group-append">
      <div [disabled]="saveSubscription && !saveSubscription?.closed" class="btn btn-primary m-0" (click)="OnSaveClicked()"><i class="fa fa-fw fa-save"></i> Speichern
      <i class="fa fa-spinner fa-spin" *ngIf="saveSubscription && !saveSubscription?.closed"></i></div>
      <div class="btn btn-default m-0" (click)="editing = false"><i class="fa fa-fw fa-times"></i></div>
    </div>
  </div>
</ng-container>-->
  <div [hidden]="!isUsingTemplate">
    error
    <ng-template appViewContainer></ng-template>
  </div>
</ng-container>
