import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MemoryService } from '../../../services/memory.service';
import { MatDialog } from '@angular/material/dialog';
import { JustToDoComponent } from '../../dialogs/justto-do/justto-do.component';
import { ProcessHandlerService } from 'src/app/process-engine/process/process-handler-dialog/process-handler.service';
import { MobileViewService } from 'src/app/services/mobile-view.service';
import { UserLogin } from 'src/app/interfaces/user/user.interface';

import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  standalone: true,
  imports: [TranslocoModule, MatMenuModule, MatButtonModule, RouterModule, MatIconModule, MatBadgeModule]
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() inspectorTasks: number;
  userLogin: UserLogin;
  roleId: number;
  mobile: boolean;
  version = localStorage.getItem('app_version');

  constructor(
    private memoryService: MemoryService,
    private readonly mobileService: MobileViewService,
    private readonly dialog: MatDialog,
    private readonly processHandlerService: ProcessHandlerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inspectorTasks) {
      this.inspectorTasks = this.inspectorTasks;
    }
  }

  ngOnInit(): void {
    this.mobile = this.mobileService.detectMobileDevice();
    this.userLogin = this.memoryService.getLoginData();
    this.memoryService.$userLoginData.subscribe((userLogin) => {
      this.userLogin = userLogin;
    });
  }

  onToDoClicked() {
    const dimensions = this.processHandlerService.getDialogScreenSize();
    this.dialog.open(JustToDoComponent, { ...dimensions });
  }

  onLogoutClicked(): void {
    // Log out
    this.memoryService.setLoggedIn(false, 'You have logged out.');
  }
}
