import { Expose, Type } from 'class-transformer';
import { DocumentSubtypeModel } from './document-type.model';
import { Stripper } from './stripper.model';

const VALIDATED = 1;
const UN_VALIDATED = 0;
const EXPIRED = -1;
const PRE_VALIDATED = 2;
const REJECTED = -1;

export class DocumentModel extends Stripper {
  @Expose() documentId: number;
  @Expose() name: string;
  @Expose() path: string;
  @Expose() type: string;
  @Expose() userId: number;
  @Expose() subtypeId: number;

  @Type(() => Date)
  @Expose()
  validationBegin: Date;

  @Type(() => Date)
  @Expose()
  validationEnd: Date;
  @Expose() validationUserId: number;
  @Expose() uploadedByUserId: number;
  @Expose() fromTrainingId: number;

  //user: UserModel;
  subtype: DocumentSubtypeModel;
  rotation = 0; // optional for images
  validated: number;

  constructor() {
    super();

    this.getValidation();
  }

  /**
   * -1 validated, but out of date
   * 0  unvalidated
   * 1  validated
   */
  getValidation(force = false): number {
    if (this.validated && !force) {
      return this.validated;
    }
    if (!this.validationBegin) {
      this.validated = UN_VALIDATED;
    } else if (!this.validationBegin && !this.validationEnd) {
      this.validated = REJECTED;
    } else {
      const now = new Date();
      const begin = new Date(this.validationBegin);
      const end = new Date(this.validationEnd);
      if (now.getTime() < begin.getTime()) {
        this.validated = PRE_VALIDATED;
      } else if (now.getTime() > end.getTime()) {
        this.validated = EXPIRED;
      } else {
        this.validated = VALIDATED;
      }
    }
    return this.validated;
  }
}
