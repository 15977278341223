import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendObservable, BackendService } from './backend.service';
import { ISequelizeCount } from '../models/sequelize-count.interface';
import { SiteModel } from '../models/site.model';
import {
  EcoMateConsumerData,
  Journey,
  VesselData
} from '../views/visits-reports/emission-report/overall-data.interface';
import { JourneyWithTrack, PastTrackModel } from '../models/pastTrack.model';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends BackendService {
  activated: boolean;

  openInGooleMaps(lat: number, lng: number): void {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    if (this.mobileService.detectIfPWA()) {
      window.location.href = url;
    } else {
      window.open(url);
    }
  }

  uploadBraMapFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.post('braMap/upload', formData);
  }

  uploadEmissionVesselFile(file: File, siteId: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('siteId', siteId.toString());

    return this.post('emission/upload', formData);
  }

  createJourney(journey: Journey): Observable<JourneyWithTrack> {
    return this.post<JourneyWithTrack>(`journeys/create`, { journey });
  }

  getJourney(siteId: number): Observable<ISequelizeCount<JourneyWithTrack>> {
    return this.get<ISequelizeCount<JourneyWithTrack>>(`journeys/${siteId}/get`);
  }

  deleteJourney(id: number): Observable<any> {
    return this.post<any>(`journeys/${id}/delete`);
  }

  getEcomateConsumerData(vesselId: string, from: string, to: string): Observable<EcoMateConsumerData[]> {
    return this.post<EcoMateConsumerData[]>(`ecomate/consumerData`, { vesselId, from, to });
  }

  getEcomateTimeseriesData(vesselId: string, from: string, to: string, interval: string): Observable<VesselData> {
    return this.post<VesselData>(`ecomate/timeseriesData`, { vesselId, from, to, interval });
  }

  getSite(siteId: number): Observable<SiteModel> {
    return this.get<SiteModel>(`${siteId}/get`);
  }

  editSite(siteId: number, mainAssetId?: number, editingOptions?: ISiteEditingOptions): Observable<SiteModel> {
    return this.post<SiteModel>(`${siteId}/edit`, { data: { mainAssetId }, editingOptions });
  }

  createSite(name: string, mainAssetId: number): Observable<SiteModel> {
    return this.post<SiteModel>(`create`, { data: { mainAssetId, name } });
  }

  getAllSites(
    criteria?: any,
    limit?: number,
    offset?: number,
    options?: IGetAllSitesOptions
  ): Observable<ISequelizeCount<SiteModel>> {
    if (!criteria && !limit && !offset && !options) {
      return this.cachingService.createCachingSubscription(
        'getAllSites',
        this.post<ISequelizeCount<SiteModel>>(`all/get`, {})
      );
    }
    return this.post<ISequelizeCount<SiteModel>>(`all/get`, { criteria, limit, offset, options });
  }

  editSiteUser(siteId: number, userId: number, presentFrom: Date, presentTo: Date): Observable<any> {
    return this.post<any>(`${siteId}/editSiteUser`, {
      userId,
      presentFrom,
      presentTo
    });
  }

  generateTvOneTimeCode(): Observable<any> {
    return this.post<any>('generate-code');
  }

  validateTvOneTimeCode(code: string): Observable<any> {
    return this.post<any>('validate-code', { code });
  }

  getHistoricalTrack(siteId: number, startTime: DateTime, endTime: DateTime): Observable<PastTrackModel[]> {
    return this.post<PastTrackModel[]>('historical-track', {
      siteId,
      startTime: startTime.toJSDate(),
      endTime: endTime.toJSDate()
    });
  }

  post<T>(url: string, body: any = {}, options?: any): BackendObservable<T> {
    return super.post<T>(`site/${url}`, body, options);
  }

  get<T>(url: string, options?: any): Observable<T> {
    return super.get<T>(`site/${url}`, options);
  }
}

export interface ISiteEditingOptions {
  retainCertificates: boolean;
}

export interface IGetAllSitesOptions {
  includeMainAsset?: boolean;
  includeWarehouses?: boolean;
  includeMainAssetAndCertificates?: boolean;
  includeTrack?: boolean;
  includeBraProcesses?: boolean;
}
