<ng-container *transloco="let t">
  <h1 mat-dialog-title>
    <div class="flex align-center">
      <mat-icon class="material-symbols-outlined">contact_support</mat-icon>
      <span>
        {{ t('support.create') }}
      </span>
    </div>
  </h1>
  <div mat-dialog-content>
    <div [formGroup]="supportData">
      <mat-form-field *ngIf="provideEmail">
        <mat-label>{{ t('support.email') }} </mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="supportData.get('email').invalid">{{ t('support.error_email') }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ t('support.problem.title') }} </mat-label>
        <input matInput formControlName="subject" />
        <mat-error *ngIf="supportData.get('subject').invalid">{{ t('support.error_subject') }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ t('support.problem.description') }} </mat-label>
        <textarea formControlName="body" matInput rows="5"></textarea>
        <mat-error *ngIf="supportData.get('body').invalid">{{ t('support.error_body') }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ t('support.problem.type') }}</mat-label>
        <mat-select formControlName="type">
          <mat-option value="Bug">{{ t('general.bug') }}</mat-option>
          <mat-option value="Feature">{{ t('general.feature') }}</mat-option>
        </mat-select>
        <mat-error *ngIf="supportData.get('type').invalid">{{ t('support.error_type') }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <span class="flex-grow-1"></span>
    <button mat-button mat-dialog-close>{{ t('general.cancel') }}</button>
    <button mat-button (click)="onCreateClicked()" [disabled]="supportData.invalid">
      {{ t('general.send') }}
    </button>
  </div>
</ng-container>
